import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DefaultRouteService } from 'routing/guards/default-route.service';
import { AuthRouteGuard } from 'routing/guards/auth.route-guard';
import { StorageService } from 'app/services/storage.service';
import { FeatureService } from 'app/services/feature.service';

// This route guard validates /post-recommendation route query parameters
// Possible route variants:
// 1. Internal recommendation, not requested: /post-recommendation?userId=[id]
// 2. Internal recommendation, requested: /post-recommendation?recommendationId=[id]&tempToken=token
// 3. External recommendation: /post-recommendation?recommendationId=id&countryCode=[code]
export const PostRecommendationRouteGuard = (next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const featureService = inject(FeatureService);
    const router = inject(Router);
    const defaultRouteService = inject(DefaultRouteService);

    if (!featureService.reviewsEnabled) {
        return defaultRouteService.createDefaultRoute(router.url, false);
    }

    const storageService = inject(StorageService);
    if (
        (!next.queryParamMap.get('recommendationId') && !next.queryParamMap.get('userId')) ||
        (!next.queryParamMap.get('tempToken') && !storageService.countryCode)
    ) {
        return defaultRouteService.createDefaultRoute(router.url, false);
    }

    if (next.queryParamMap.get('tempToken')) {
        return AuthRouteGuard(next, _state);
    }

    return true;
};
