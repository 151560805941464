import { BaseParser } from 'app/parsers/base-parser';
import { ServerResponseData } from 'app/models/api/response';
import { ParserMap } from 'app/parsers/response-parser';
import { Recommendation } from 'app/models/api/recommendation';

export class RecommendationParser extends BaseParser {
    parse(data: ServerResponseData, included: ServerResponseData[], parsersMap: ParserMap) {
        const recommendation = super.parseBase(Recommendation, data);
        recommendation.user = this.getRelationship('user', data, included, parsersMap);
        return recommendation;
    }
}
