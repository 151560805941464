import { BaseApiModel } from 'app/models/api/response';
import { User } from './user';

export class Recommendation extends BaseApiModel {
    authorName: string;
    status: 'requested' | 'published';
    description: string;
    created: string;
    score: number;
    user?: User;
    links: {
        postExternalRecommendationUrl?: string;
    };
}
